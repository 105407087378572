import axios from 'axios'
const store = require('./index')
const getenv = require('../../getenv')

const esc = encodeURIComponent

const convertToQuery = payload =>
  Object.keys(payload)
    .map(k => esc(k) + '=' + esc(payload[k]))
    .join('&')

var UsernameUserForTokenGeneration = null
var tokenStorage = {}

const axiosClient = axios.create()

export const ApiClient = {
  getCancelToken () {
    return axios.CancelToken
  },
  async getToken (username, password, enviroment = "platformENV") {
    const platformENV = getenv.getenv(enviroment, store.default.state.isProd, store.default.state.platform)
    if (enviroment === "platformENV") {
      const data = {
        username: username,
        password: password,
        grant_type: 'password'
      }
      const headers = {
        'content-type': 'application/x-www-form-urlencoded'
      }
      const tokenResponse = await axiosClient.post(`/${platformENV}/Token`, convertToQuery(data), { ...headers, ...{ useCredentails: true } })
      tokenStorage[platformENV] = tokenResponse.data.access_token
    } else {
      const headers = {
        'X-Client-Id': getenv.getRegisteryClientId(platformENV)
      }
      const tokenResponse = await axiosClient.get(`/${platformENV}/v1/client`, { headers })
      tokenStorage[platformENV] = tokenResponse.data.data.authToken
    }
    if (tokenStorage[platformENV] == null){
      return false
    } else {
      return true
    }
  },
  async post (username, password, data, path, enviroment = "platformENV", errCallback = null, cancelToken = null) {
    const platformENV = getenv.getenv(enviroment, store.default.state.isProd, store.default.state.platform)
    var token = tokenStorage[platformENV]

    if (token === null || UsernameUserForTokenGeneration === null || UsernameUserForTokenGeneration !== username) {
      var tokengenerated = await this.getToken(username, password, enviroment)
      token = tokenStorage[platformENV]
      if (tokengenerated){
        UsernameUserForTokenGeneration = username
      }
    }
    const pathOfApi = "/" + platformENV + path
    var headers = {}
    if (enviroment === "platformENV") {
      headers = {
        withCredentials: true,
        authorization: `bearer ${token}`,
        "content-type": "application/json"
      }
    } else {
      headers = {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json"
      }
    }
    var response = data === '' ? await axiosClient.post(`${pathOfApi}`, { headers }) : await axiosClient.post(`${pathOfApi}`, data, { headers })
      .catch(function (error) {
        return error.response
      })
    return response
  },

  async get (username, password, data, path, enviroment = "platformENV", errCallback = null, cancelToken = null) {
    const platformENV = getenv.getenv(enviroment, store.default.state.isProd, store.default.state.platform)
    var token = tokenStorage[platformENV]
    if (token == null || UsernameUserForTokenGeneration === null || UsernameUserForTokenGeneration !== username) {
      var tokengenerated = await this.getToken(username, password, enviroment)
      token = tokenStorage[platformENV]
      if (tokengenerated){
        UsernameUserForTokenGeneration = username
      }
    }
    const pathOfApi = "/" + platformENV + path
    var headers = {}
    if (enviroment === "platformENV") {
      headers = {
        withCredentials: true,
        authorization: `bearer ${token}`,
        "content-type": "application/json"
      }
    } else {
      headers = {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json"
      }
    }
    var response = data == null ? await axiosClient.get(`${pathOfApi}`, { headers }) : await axiosClient.get(`${pathOfApi}`, data, { headers })
      .catch(function (error) {
        return error.response
      })
    return response
  },
  async delete (username, password, data, path, enviroment = "platformENV", errCallback = null, cancelToken = null) {
    const platformENV = getenv.getenv(enviroment, store.default.state.isProd, store.default.state.platform)
    var token = tokenStorage[platformENV]
    if (token == null || UsernameUserForTokenGeneration === null || UsernameUserForTokenGeneration !== username) {
      var tokengenerated = await this.getToken(username, password, enviroment)
      token = tokenStorage[platformENV]
      if (tokengenerated){
        UsernameUserForTokenGeneration = username
      }
    }
    const pathOfApi = "/" + platformENV + path
    var headers = {}
    if (enviroment === "platformENV") {
      headers = {
        withCredentials: true,
        authorization: `bearer ${token}`,
        "content-type": "application/json"
      }
    } else {
      headers = {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json"
      }
    }
    var response = data == null ? await axiosClient.delete(`${pathOfApi}`, { headers }) : await axiosClient.delete(`${pathOfApi}`, data, { headers })
      .catch(function (error) {
        return error.response
      })
    return response
  },
  async put (username, password, data, path, enviroment = "platformENV", errCallback = null, cancelToken = null) {
    const platformENV = getenv.getenv(enviroment, store.default.state.isProd, store.default.state.platform)
    var token = tokenStorage[platformENV]
    if (token == null || UsernameUserForTokenGeneration === null || UsernameUserForTokenGeneration !== username) {
      var tokengenerated = await this.getToken(username, password, enviroment)
      token = tokenStorage[platformENV]
      if (tokengenerated){
        UsernameUserForTokenGeneration = username
      }
    }
    const pathOfApi = "/" + platformENV + path
    var headers = {}
    if (enviroment === "platformENV") {
      headers = {
        withCredentials: true,
        authorization: `bearer ${token}`,
        "content-type": "application/json"
      }
    } else {
      headers = {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json"
      }
    }
    var response = data == null ? await axiosClient.put(`${pathOfApi}`, { headers }) : await axiosClient.put(`${pathOfApi}`, data, { headers })
      .catch(function (error) {
        return error.response
      })
    return response
  }
}
